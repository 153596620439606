require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start()
import Rails from '@rails/ujs';

import Swiper, { Navigation, Autoplay, Lazy, Keyboard, Thumbs } from 'swiper'
Swiper.use([Navigation, Autoplay, Lazy, Keyboard, Thumbs])
import Macy from 'macy'

const initMainNav = () => {
  const hamburger = document.getElementById('main-nav-toggle');
  const mainNav = document.querySelector('.main-nav');

  hamburger.addEventListener('click', () => {
    hamburger.classList.toggle('is-active');
    mainNav.classList.toggle('main-nav--open');
    document.body.classList.toggle('main-nav-open')
  });
}

const positionSwiperButtons = (swiper, prevButton, nextButton) => {
  if (document.body.offsetWidth >= 992) {
    prevButton.style.top = null
    nextButton.style.top = null

    return
  }

  const slideIndex = swiper.activeIndex
  const slide = swiper.slides[slideIndex]

  if (!slide) {
    return
  }

  const slideImage = slide.querySelector('img')
  const imagePath = slideImage.dataset.src || slideImage.src

  const image = new Image()
  image.onload = function() {
    const ratio = image.width / image.height

    const buttonOffsetTop = `${slide.offsetWidth / ratio / 2}px`

    prevButton.style.top = buttonOffsetTop
    nextButton.style.top = buttonOffsetTop
  }
  image.src = imagePath
}

const initSlider = (element) => {
  if (element.classList.contains('swiper-container-initialized')) {
    return
  }

  const parent = element.parentElement
  const nextElement = parent.nextElementSibling
  let thumbsSwiper

  if (nextElement && nextElement.classList.contains('slider-thumbs')) {
    thumbsSwiper = new Swiper(nextElement.querySelector('.swiper-container'), {
      slidesPerView: 'auto',
      // spaceBetween: 20,
      lazy: {
        loadPrevNext: true,
        loadPrevNextAmount: 12
      },
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
    })
  }

  const prevButton = parent.querySelector('.swiper-button-prev')
  const nextButton = parent.querySelector('.swiper-button-next')

  new Swiper(element, {
    loop: true,
    autoHeight: true,
    lazy: {
      loadPrevNext: true
    },
    centeredSlides: true,
    navigation: {
      prevEl: prevButton,
      nextEl: nextButton,
    },
    keyboard: {
      enabled: true,
      onlyInViewport: false,
    },
    on: {
      slideChange: (swiper) => positionSwiperButtons(swiper, prevButton, nextButton),
      resize: (swiper) => positionSwiperButtons(swiper, prevButton, nextButton),
    },
    thumbs: {
      swiper: thumbsSwiper,
    },
  })
}

const initSliders = () => {
  for (const element of document.querySelectorAll('.slider')) {
    if (!element.closest('.modal')) {
      initSlider(element.querySelector('.swiper-container'))
    }
  }
}

const initForms = () => {
  document.addEventListener('keyup', (e) => {
    const element = e.target
    const nextElement = element.nextSibling

    if (nextElement && nextElement.classList && nextElement.classList.contains('form__error')) {
      nextElement.remove()
    }
  });
}

const initMasonry = () => {
  for (const element of document.querySelectorAll('.masonry')) {
    Macy({
      container: element,
      columns: 3,
      trueOrder: true,
      margin: {
        x: 5,
        y: 5
      },
      breakAt: {
        992: 2,
        540: 1
      }
    })
  }
}

const initModals = () => {
  for (const element of document.querySelectorAll('[data-toggle="modal"]')) {
    element.addEventListener('click', (e) => {
      e.preventDefault()

      const currentElement = e.currentTarget
      const modal = document.querySelector(currentElement.dataset.target)
      modal.classList.add('modal--open')
      document.body.classList.add('modal-open')
      const swiper = modal.querySelector('.swiper-container')

      if (swiper) {
        initSlider(swiper)
        swiper.swiper.slideTo(currentElement.dataset.slideIndex, 0)
      }
    })
  }

  for (const element of document.querySelectorAll('.modal__close')) {
    element.addEventListener('click', (e) => {
      e.preventDefault()

      const modal = e.currentTarget.closest('.modal')
      modal.classList.remove('modal--open')
      document.body.classList.remove('modal-open')
    })
  }

  document.addEventListener('keyup', (e) => {
    if (e.key !== 'Escape') {
      return
    }

    for (const element of document.querySelectorAll('.modal')) {
      element.classList.remove('modal--open')
      document.body.classList.remove('modal-open')
    }
  })
}

window.contactFormSubmit = function() {
  const recaptcha = document.querySelector('.g-recaptcha')

  if (!!recaptcha) {
    const form = recaptcha.closest('form')

    if (form) {
      const messages = form.querySelectorAll('.form__error, .form__success')

      messages.forEach((message) => {
        message.remove()
      })

      form.classList.add('loading')
      Rails.fire(form, 'submit')
    }
  }
};

document.addEventListener('turbolinks:load', () => {
  initMainNav()
  initSliders()
  initForms()
  initMasonry()
  initModals()
})